import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { CardWithImageLink } from '../../../components/Card';
import { ButtonOutbound, ButtonGhost } from '../../../components/Button';
import { designTokens } from '../../../components/Theme/designTokens';
import { OutboundLink } from '../../../components/StyledLink';
import { CallToAction } from '../../../components/CallToAction';
import { IconBox, IconCard } from '../../../components/IconBox';
import { Text } from '../../../components/Text';
import { Box, ProjectBox } from '../../../components/Box';
import ImageIcon from '../../../components/ImageIcon';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdHammer from 'react-ionicons/lib/MdHammer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Zakucons" mdxType="SEO" />
    <IntroProject projectName="Zakucons" projectDescription="Icons inspired by the world of Mobile Suit Gundam" projectYear="2016" projectImage="zakucons-preview" mdxType="IntroProject" />
    <Section section="Zakucons" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Overview</h5>
          <IconCard cardTitle="Specifications" cardText="A small, personal side project to try and satiate my inner kid" mdxType="IconCard">
          <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
          </IconCard>
          <IconCard cardTitle="Tools" cardText="Sketch, Illustrator, HTML, CSS, JS" mdxType="IconCard">
            <MdHammer fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdHammer" />
          </IconCard>
          <p>An open-source icon set inspired by the fictional world of <OutboundLink rel="noopener noreferrer" underline="true" href="https://en.wikipedia.org/wiki/Gundam" mdxType="OutboundLink">Mobile Suit Gundam</OutboundLink></p>
          <ProjectBox image="zakucons-example-icon" title="Zakucons" description="Download the icons from the icon website." link="https://grapalab.com/zakucons/" mdxType="ProjectBox" />
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAACE4AAAhOAFFljFgAAACYklEQVQ4y5WUSXcaMRCE/f//Va455MUkMfsyKzCrlgFsY6vztcAx+JaDXo1a6lJXV8ODtUGcE1G05l1s/yamO4uJ+HaJcWaa13hm+/d49hG/YrhyhId/hC0Ju4N0y1pMasQkvfTzvdjcia1fpF810hPv1q30LL2jqDmuOwcLh7sl7Hh1tztKTXKWOVkuG1mtWkkg1nheDlJVz9JwnmZWcu5knKVgVT8H779U2LVnKQsvNUmKaWLk1yiLpHsI12BBtXquZ3lqI6brTqr96ZPQaR/YuOokPu3FINkXTg5IOZZejqB+WySbTSc9y0x3rK04WrL/9ijdfB/c8abCYRApqOrpqZQVUmezvYzHW5mQqLHRKKUNViuJSlL6pxVPxqW0VNzWL/emRMIcwj+FLLk4VaLf+QWJPT4mF0KSO8wr6afux5OddExE254/CaNkCN0Wt5AxcNHNuPh9KoO2gcYPyHUbHKUl6nr/cy0e6SfO+0WlTgc33FTo2cRXafQYiQmNzjZXd9XxRS3zeRXbsSG+mO5lztK7Gca0zWtw/otkdXZJkvZPxyKjkoZhzsEZ0jS+4iElnfPAApwQz8lr2tfg7wb7wCbBwR/L6LRFpkGKxwSrQzxKZMBxx0MGZzskO9rg9Uegw7873leoM6Ty9PUESVudR/Yqb0NVWnmMY1xFfEtrcvZr7hd819XpbrADkkOe+8CIBFwOyAg1fYEwpKkN6ytuqaRuXkLFKspDjBflEO/eDzb6PT/yoT9HFx2joUYN13U4XFCTtFeKg7+JX/9cGJ1LhfqhaE0I/JOA75f9f6wPjr+QHWtqnbh1ZgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Zakucons",
          "title": "Zakucons",
          "src": "/static/dad61cc8a58c0a6a5d78fd91cecbf9c3/d9199/icons-1.png",
          "srcSet": ["/static/dad61cc8a58c0a6a5d78fd91cecbf9c3/8ff5a/icons-1.png 240w", "/static/dad61cc8a58c0a6a5d78fd91cecbf9c3/e85cb/icons-1.png 480w", "/static/dad61cc8a58c0a6a5d78fd91cecbf9c3/d9199/icons-1.png 960w", "/static/dad61cc8a58c0a6a5d78fd91cecbf9c3/07a9c/icons-1.png 1440w", "/static/dad61cc8a58c0a6a5d78fd91cecbf9c3/29114/icons-1.png 1920w", "/static/dad61cc8a58c0a6a5d78fd91cecbf9c3/97a96/icons-1.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Download" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <CSSGrid mdxType="CSSGrid">
            <Col mdxType="Col">
              <div style={{
                  textAlign: 'center'
                }}>
                <p>Go to the website or copy the project in Figma!</p>
              </div>
            </Col>
            <Col col={1 / 2} media={{
                phone: 1 / 2
              }} mdxType="Col">
              <div style={{
                  textAlign: 'center'
                }}>
                <div style={{
                    width: '104px',
                    margin: 'auto'
                  }}>
                  <ImageIcon srcName="zakucons-example-icon" mdxType="ImageIcon" />
                </div>
                <br />
                <ButtonOutbound marginBottom={designTokens.space[4]} marginTop={designTokens.space[4]} color={({
                    theme
                  }) => theme.grey0} bg={({
                    theme
                  }) => theme.grey900} href="https://grapalab.com/zakucons/" target="_blank" mdxType="ButtonOutbound">View the Icons</ButtonOutbound>
              </div>
            </Col>
            <Col col={1 / 2} media={{
                phone: 1 / 2
              }} mdxType="Col">
              <div style={{
                  textAlign: 'center'
                }}>
                <div style={{
                    width: '104px',
                    margin: 'auto'
                  }}>
                  <ImageIcon srcName="figma-icon" mdxType="ImageIcon" />
                </div>
                <br />
                <ButtonOutbound marginBottom={designTokens.space[4]} marginTop={designTokens.space[4]} bg={({
                    theme
                  }) => theme.green} href="https://www.figma.com/file/HFAhFO6OlqFydbRpuPaLO2/Zakucons?node-id=0%3A1" target="_blank" mdxType="ButtonOutbound">Copy in Figma</ButtonOutbound>
              </div>
            </Col>
          </CSSGrid>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAACE4AAAhOAFFljFgAAACrElEQVQ4y6WU3U+SURzH/Ze88QVfNrEXp1lbTW2aOEJTQZBXAZtj3Firra0rqzWXbS0jvcqMWgE3ob0w5aYLLCllxvuDAqIk59s5Bx7UtWqt3/Z5zgu/3/c5Z9/fQwWOBCEE/xqlGrGQVIibotjn9XUs+3z46F/Fh9UVDptzVov4/H68ozmRaFQU/VWQRSabhWP6ATyzT+GiuCmeJ3NYnJ7By4eP4HbM481jB1yOOTynea8WFnhdoVAQNY8LCvt7cOmuInNRjZhMj4RMh0jvKEI9aoQuqbEu02BTrsPGgAHezst4fed+8Vi/E0xRwRcDWmy19CLYLkfwwgBWBo1YVmjh6R+Fq18Dd3s3vOf7cK+pFbM3b/1ZUNjLYVGmRPhkD7629SHYJkOwaxifuobw7GwP5ls7MdN0BtOnzuGaRIrbNvtfBLMZOFUmxPvNCA1ZOJvDVqwNmeGnJ/Ve0eG90oTZQTVs3TLcvX4DhaLHh4JHHU4kElh+6y369eMAuXQG+ewu9ndzyJdg+2IsLS1he3u7bDR7HBNMCSm4PR7s5fNICgK+bWwgsLZW5gttqa3wd0RjMQipFFxuN9Lp9HFBtjo4KL41EAig5XQLpFIpTjQ3o5mODOmRUaSxsREdHR0IhUJcsKRBKmgPFXswk4HBYEBlZSWqqqpQU1OD2tpaVFdXl+cSiQQNDQ2or69HXV0d3zOZTBAEgYj9yAXz9IpTU1OQy+VQqVRQKpXQaDQ82Wg0lmHrsbEx6PV6jIyM8ByFQoHJyUkSj8fLLhOfz0eoENHpdESr1XLoaQktJhaLhdhsNmK328nExASxWq1kfHycmM1m/hvbY3VOp/Pwyuy6kUgE4XCYw+aMKP1WY9SAZDLJrsVH1gniOkWNYezs7CCXyzEvuMuk9G/x37D4CZlD2XkZgSxRAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Zakucons website",
          "title": "Zakucons website",
          "src": "/static/27da34a6d011b29d4b2e5eae691ae79e/d9199/preview-1.png",
          "srcSet": ["/static/27da34a6d011b29d4b2e5eae691ae79e/8ff5a/preview-1.png 240w", "/static/27da34a6d011b29d4b2e5eae691ae79e/e85cb/preview-1.png 480w", "/static/27da34a6d011b29d4b2e5eae691ae79e/d9199/preview-1.png 960w", "/static/27da34a6d011b29d4b2e5eae691ae79e/07a9c/preview-1.png 1440w", "/static/27da34a6d011b29d4b2e5eae691ae79e/29114/preview-1.png 1920w", "/static/27da34a6d011b29d4b2e5eae691ae79e/97a96/preview-1.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Selection" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Single SVG's</h5>
          <p>If only a single icon is needed, download as scalable svg's</p>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAACE4AAAhOAFFljFgAAABmklEQVQ4y22TbW/CMAyE+/9/47RvY9qg9BXS0mLPj4nbFC1SFHHY5/PZrdq21WmaVER0XVe93W7a972O46jP59PxZVkc67rO/wfjppT8d9M0wmtHKsggIpn3fr970DzPjgUORizkgRMDqeECbkWkIphqkWjVdBgGfcch5OWEcgrQCcoyLlXILxVCSItB+ng8PBE87CE+CM2KXSGy31vmQlJ6GC1HbLRMrL2SMakwmuRyKAS5inX3ECUQR8tc8vCQlrPqvWVMSNbS6eNT0/ePprrRqe00Xa46Myi72BDFuRTOk98Vlq3NVu3366T9pdbUm1+jtTmMOhk+mEIIypaxIPu6K4y9ImCx4Ppae/Kai6Acr5g+7QVZKGSPDT+2HEF4RABtrHnfIvF8PruiiC33UF7nRfjfAnNLJcRQKOwJHDKGZfhxymVVklBUYjHlctlDOYOydyfMIwcQSxCW1JL9N5djVjhuAzjgRiamXA6E0XLsIUqyLxuO6liZ96EwZTg2D6mQPXCF7BQVOfwG50vIn9cWx8u6gOcvxe8f0HaXgdCF8pQAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Zakucons Download",
          "title": "Zakucons Download",
          "src": "/static/567daaf2d4ba9a33a29dd63599278e52/d9199/preview-2.png",
          "srcSet": ["/static/567daaf2d4ba9a33a29dd63599278e52/8ff5a/preview-2.png 240w", "/static/567daaf2d4ba9a33a29dd63599278e52/e85cb/preview-2.png 480w", "/static/567daaf2d4ba9a33a29dd63599278e52/d9199/preview-2.png 960w", "/static/567daaf2d4ba9a33a29dd63599278e52/07a9c/preview-2.png 1440w", "/static/567daaf2d4ba9a33a29dd63599278e52/29114/preview-2.png 1920w", "/static/567daaf2d4ba9a33a29dd63599278e52/97a96/preview-2.png 2400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      